.container{
    min-height: 80px;
    width: 100vw;
    background-color: rgba(226, 234, 237, 0.889);
    color: black;

}

.socialMedia{
    width: 30px;
    // filter: invert(1);
    position: relative;
    transition: .2s;

    &:hover{
        transform: scale(0.92);
        cursor: pointer;
        filter: invert(0);
    }
}

.pageLayout{
    padding: 15px 0 ;
    height: 100%;
    min-width: 80%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10%;
    @media (max-width: 800px) {
        min-height: 140px;
       flex-direction: column;
       justify-content: space-around;
       gap: 14px;
       margin: 0;
    }
}

.socMedia{
    display: flex;
    gap: 20px;
}

.contacts{
    display: flex;
    align-items: center;
    gap: 5px;
    transition: .2s;

    &:hover{
        transform: scale(0.95);
        color: gray;
        cursor: pointer;
    }
}

.navigation{
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
 

    @media (max-width: 800px) {
        min-width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10%;
        width: 100%;
        padding: 10px 0;
    }

    
}
.legal{

    align-items: start;
    font-size: 10px;
    @media (min-width:800px) {
        gap: 2px;
    }
    @media (max-width:800px) {
        gap: 10px;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
    }
}
.navParent{
    @media (max-width: 800px) {
        width: 100%;
    }
}
.navigationItem{
    color: black;

    &:hover{
        transform: scale(0.95);
        color: black;
        transition: 0.2s;
        cursor: pointer;
    }
}