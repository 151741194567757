.headerLayout{
    width: 100vw;
    max-width:100%;
    height: 100px;
    max-height: 30vh;
    position: fixed;
    background-color: rgb(226, 234, 237);
    display: flex;
    justify-content: flex-end;
    box-shadow: 0.2px 7.4px 6.4px hsl(0deg 0% 0% / 0.40);
    z-index: 2;
}
h1{
    &:hover{
        cursor: pointer;
        color: white;
    }
    color: black;
    @media (max-width:1000px){
        font-size: 1.5rem;
    }
    @media (max-width:500px){
        font-size: 1.1rem;
       }
}

.cartBackground{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    margin: auto;
    width: 100%;
    height: 100%;
    margin: auto;
transform: translate(0, 0);
    img{
        width: 100%;
        position:absolute;
    };
}

.header{
    width: 95%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    color: white;
    align-items: center;
    // margin: 0 0 30px 0;
    // padding: 0 25px;
}

// .cartBackground{
//     object-fit: cover;
//     max-width: 70px;
//     height: 70px;
//     position: absolute;
//     right: 5px;
//     top: 15px;
//     @media (max-width:1000px){
//         font-size: 0.8rem;
//     }
//     @media (max-width:500px){
//         max-width: 50px;
//         height: 50px;
//        }
// }

.navigation{
    display: flex;
    min-width: 50%;
    justify-content: center;
    align-items: center;
    gap: 8vw;
    @media (max-width:700px){
       gap: 10%;
      }
      @media (max-width:500px){
        gap: 5%;
        // min-width: 63%;
        min-width: 55%;
       }
}

.navigationItem{
    transition: 0.4s;
    font-size: 1.3rem;
    font-weight: 500;
    color: black;
    &:hover{
        transform: scale(1.1);
        cursor: pointer;

    }
    @media (max-width:1000px){
        font-size: 0.8rem;
    }
    @media (max-width:500px){
        // remove rotation, reduce font-size and remove width
        // transform: rotate(45deg);
        font-size: 0.80rem;
        width: 70px;
       }
}

.cartIcon{
    position: absolute;

}
.cart{
    width: 110px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
        transition: 0.2s;
        transform: scale(1.13);
    }
    @media (max-width:1000px){
        width: 110px;
    }
    @media (max-width:500px){
        width: 90px;
    }
    
}

.itemsInCart{
    position: absolute;
    transform: translateX(11px) translateY(-15px);
    background-color: red;
    color: white;
    font-size: 10px;
    font-weight: bold;
    width: 13px;
    height: 13px;
    border-radius: 50%;
}
.emptyCart{
    visibility: hidden;
}