*{
    transition: 0.3s;
}

.container{
    position: relative;
    width: 100%;
    overflow: hidden;   
&:hover{   
}
}

.imgBox{
    overflow: hidden;
    height: 100%;
}
.imgBox img{
    max-width: 100%;
    height: 100%;
    object-fit: scale-down;
    &:hover{
        

    }

}

.card{
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 55px;
    color:  black;
    // justify-content: space-between;
    @media (max-width:1100px) {
        gap: 15px
    }
    @media (max-width:700px) {
        gap: 5px;
    }
    border-bottom: solid 1px black;

    &:hover{
        cursor: pointer;
        img{
            cursor: pointer;
            transform: scale(0.9);
        }
        .label{
            transform: scale(1.1);
            background-color: black;
            color: white;
            border-radius: 50px;
        }
    }

}

.content{
    display: flex;
    flex-direction: column;
    // gap: 55;
    justify-content: space-around;
    min-height: 100%;
    width: 100%;
    // padding: 15px;
   
}

.label{
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0 15px;
    //  &:hover{
    //     cursor: pointer;
    //     transform: scale(1.1);
    //     background-color: black;
    //     color: white;
    //     border-radius: 50px;
    //  }
}
.price{
    padding: 0 15px;
    text-align: end;
    font-weight: 700;
    font-size: 1.1rem;
}

.labelContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.removeItem{
    top: 10px;
    position: absolute;
    right: -60px;
    display: flex;
    font-weight: 700;
    // transform: translateX(70px);
    &:hover{
        cursor: pointer;
        right: 0;
    }
}

