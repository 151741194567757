.aboutPage{
    max-width: 100vw;
    // overflow: hidden;
    margin: 0px 0 25px 0;
    height: 40vh;
    font-size: 5vh;
    font-family: "Work Sans", sans-serif;
    line-height: 1.5;
    @media (max-width:1100px) {
        height: 90vh;
    }
}

.background{
    position: absolute;
    height: 40vh;
    min-width: 100%;
    object-fit: cover;
    left: 0;
    z-index: -1;
    @media (max-width:1100px) {
        height: 90vh;
    }
}


.intro{
    margin: 0px;

}

.info{
    font-size: 2.9vh;
    font-weight: 550;
    letter-spacing: -2%;
    line-height: 110%;
    padding: 0px;
    }
.aboutMe{
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    display: flex;
    padding: 0px;
}

.portraitPhoto{
    max-width: 35vw;
    border-radius: 15px;
    @media (max-width:1100px) {
        max-width: 50vw;
    }
    @media (max-width:700px) {
        max-width: 80vw;
    }
}

.story{
    font-size: 24px;
    line-height: 1.1em;
    @media (max-width:700px) {
        font-size: 20px;
    }
}

.aboutInfoPage{
    display: flex;
    align-items: stretch;
    align-items: center;
    justify-content: center;
    flex: 0 0 44%;
    gap: 25px;
    box-shadow: rgba(0, 0, 0, 0.39) 0px 3px 8px;
    border-radius: 15px;
    padding: 15px;
    background-color:  rgb(226, 234, 237);
    color:  rgb(70, 88, 95);
    @media (max-width:1100px) {
        flex-wrap: wrap;
        flex: 0 0 80%
    }
}

.biography{
    // font-size: 24px;
    text-align: start;
    line-height: 120%;
    font-weight: lighter;
    li{
        padding: 2.5px;
    }
    
    @media (max-width:1400px) {
        // font-size: 0.9rem;
    }
}

.articlesPage{
    display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   margin: 30px 0;
   align-items: stretch;
   justify-content: center;
   @media (min-width:1400px) {
    // flex-wrap: nowrap;
   }
   &.articlesPage>* {
    flex: 0 0 33.3333%;
    @media (max-width:1100px) {
        flex: 0 0 70%;
    }
    @media (max-width:700px) {
        flex: 0 0 80%;
    }
}

}

.exhibitionContainer{

    display: flex;
    margin: 5px;
    max-height: 100px;
    justify-content: space-between;
}
.exhibitionInfoContainer{
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    width: 100%;
    padding: 0px 5px;
    align-items: center;
    // flex: 10% 40% 50%;
}
.exhibitionTitle, .exhibitionYear{
    font-weight: 900;
    font-size: 18px;
    padding: 2px 15px;
    flex: 45%;
    &.exhibitionYear{
        border-right: 1px solid black;
        flex: 65px;
        text-align: start;
        @media (max-width:700px) {
            border-right: 0px solid black;

        };
    }
    
    @media (max-width:700px) {
        font-size: 12px;
        padding: 2px 2px;
    }
}

.exhibitionLocation{
    flex: 45%;
    font-size: 16px;
    display: flex;
    justify-content: start;
    @media (max-width:700px) {
        font-size: 12px;
    }

}
.exhibitionsLayout{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
            gap: 10px;
    // animation: carrucell  30s ease-in-out infinite;;
    box-shadow: rgba(0, 0, 0, 0.39) 0px 3px 8px;
    background-color: rgb(226, 234, 237);
    margin: 25px 0;
    border-radius: 30px;

}

@keyframes carrucell {
    0% { transform: translateX(0);}
    50% {transform: translateX(-100%);}
    100% {transform: translateX(0%);}
  }