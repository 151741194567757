body.active-modal {
  overflow-y: hidden;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 20px;
  padding: 15px 0;
}
.bold {
  font-weight: bold;
}

.defaultButton {
  width: 70px;
  height: 30px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: turquoise;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: 0.3s;
}

.addPushUps:hover {
  color: black;
}

.productImg {
  max-width: 40vw;
  max-height: 40vh;
}

.modalButton {
  width: 130px;
  font-weight: bold;
  outline: solid 1px black;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-right: 5px;
  padding: 0 10px;

  &:hover{
    background-color: black;
    color: white;
    cursor: pointer;
  }
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;

}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.modalContent {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background-color: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  // max-width: 600px;
  min-width: 300px;
  z-index: 4;
  max-height: 90vh;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.confirmButton,
.closeButton {
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
    &.confirmButton{
      color: white;
      background-color: black;
      border: solid 1px rgb(57, 87, 57);

    }
    &.closeButton{
      color: black;
      background-color: orange;
      border: solid 1px rgb(194, 100, 0);

    }
    &:hover{
      .confirmButton{
        color: white;
      }
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px ;
      transform: scale(1.05);
    }
}

.closeModal {
  position: absolute;
  right: 10px;
  top: 10x;
  cursor: pointer;
  transition: .3s;
  &:hover{
    transform: scale(1.05);
  }
}

.removeItemButton{
  @extend .closeButton;
  background-color: red;
}

.modalButtonRemoveFromCart{
  display: flex;
  color: red;
}