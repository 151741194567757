*{
    // transition: 0.2s;
}

.imgBox{
    overflow: hidden;

}
.imgBox img{
    max-width: 180px;
    max-height: 180px;
    @media (max-width:700px) {
        max-width: 100%;
        max-height: 100%;
    }
}
// .active{
//     transform: translate(0, 0) scale(1);
//   transition: .5s;
// }
// .originalLabel{
//     // background-color: orange;
//     min-width: 90px;
//     font-weight: 700;
//     border-radius: 20px;
//     // position: absolute;
//     padding-bottom: 10px;
// }

.content, .cartContent{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  //del sterilumo reik isjungt
    border-radius: 16px;
    transition: 0s;
    max-height: 190px;
    overflow: hidden;
    color: black;
    background-color: white;
}
.cartContent{
    box-shadow: none;
    border-radius: 0px;
}
.price{
    font-weight: 700;
}

.container{
    // position: relative;
}

.card{
    // position: relative;
    height: 240px;
    width: 190px;
    //testinam steriluma //gotta set the width on small screen to 90%
    // background-color: white;
    // padding: 20px;
    // border-radius: 15px;

    //testinam steriluma
    flex-direction: column;
    transition: 0.3s;
    gap:8px;
    display:flex;
    justify-content: space-between;
    z-index: -1;
    transition: 0.3s;
    // transform: translate(0, 200px) scale(0);
    &.active{
    transform: translate(0, 0) scale(1);
    }
    &:hover{
        transform: scale(0.92);
        cursor: pointer;
        .content{
            background-color: black;
            color: white;
        }
    }
    @media (max-width:700px) {
        width: 100%;
        height: 100%;
    }
}