.mainPage{
    min-height: 20vh;
    padding-top: 50px;
    position: relative;
}
.title{
    font-size: xx-large;
    font-weight: 700;
}

.message{
    font-weight: 500;
    font-size: x-large;
    padding: 30px 0;
}

.signature{
    position: relative;
    right: -30%;
    bottom: 0%;
    img{
        height: 100px;
    }
}