.pageLayout{
    display: flex;
    flex-direction: row;
    gap: 10%;
    width: 100%;
    padding-top: 20px;
    @media (max-width:1100px) {
      flex-direction: column;
      gap: 30px;
    }
}

.paymentOptions{
  width: 40%;
  border: 1px solid black;
  background-color: white;
  gap: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  padding-bottom: 20px;
  @media (max-width:1100px) {
    width: 100%;
  }
}

.paysera, .card, .applePay{
  height: 80px;
  border-radius: 30px;
  width: 50%;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: solid 1px black;
  &:hover{
    cursor: pointer;
    transform: scale(0.93);
    background-color: orange;
  }
}
.paysera{
  img{
    width: 70%;
  }
}

.card{
  img{
    width: 29%;
    height: 80px;
  }
}
.applePay{
  img{
    width: 50%;
  }
}

.loader{
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  &::after {
      content: "";
      z-index: 999;
      position: absolute;
      width: 160px;
      height: 160px;
      border: 14px solid transparent;
      border-top-color: black;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }

  .disabled{
    background-color: dimgrey;
    color: linen;
    opacity: .7;
  }