.pageLayout{
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    margin: 30px;
    // outline: solid 0.5px black;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    gap: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background-color: rgb(255, 255, 255);
    width: 450px;
    background-color:  rgb(226, 234, 237);
    color:  rgb(70, 88, 95);
    flex-wrap: wrap;
    @media (max-width:1100px) {
        margin: 30px 0;
    }
    &:hover{
        cursor: pointer;
        transform: scale(0.95);
        transition: 0.2s;
    }
}

.articlePhoto{
    max-height: 200px;
    max-width: 30vw;
    @media (max-width:1100px) {
        max-width: 50vw;
    }
    @media (max-width:700px) {
        max-width: 66vw;
    }
}

.articleTitle{
    font-size: 1.7rem;
    font-weight: 500;
    @media (max-width:1100px) {
        font-size: 1.5rem;
    }
    @media (max-width:700px) {
        font-size: 1.3rem;
    }
}

.hidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100px);
    transition: all 1s;
}
.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    // background-color: red;
}
    

@keyframes fade-in {
    from {scale: 0.3; opacity: 0;}
    to { scale: 1; opacity: 1;}
    
}