.pageLayout{
    // margin-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.mainView{
    display: flex;
    gap: 25px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
    @media (max-width: 1100px) {
        flex-direction: column;
        width: 95vw
    }
}

.total{
    font-weight: 700;
    font-size: 1.3rem;
    text-align: end;
}

.totalContainer{
    width: 100%;
}

.itemsInCart{
    width: 66%;
}
.shippingContainer{
    width: 30%;
    height: 280px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    @media (max-width: 1100px) {
        width: 70%;
    }
    @media (max-width: 700px) {
        width: 80%;
    }
}

.cartInfo{
    padding: 0 10%;
    display: flex;
    flex-direction: row;
    gap: 10%;
    @media (max-width: 1100px) {
        padding: 0 7%;
        gap: 7%;
    }
    @media (max-width: 700px) {
        padding: 0 5%;
        gap: 6%;
    }
}

.label{
    font-weight: 550;
    text-align: start;
    width: 25%;
    font-size: 1.1rem;
}
.info{
    text-align: start;
    font-size: 1.05rem;
}
.buttonContainer{
    width: 100%;
    display: flex;
    justify-content: end;
}

.nextButton{
    margin: 15px 8%;
    bottom: 0;
    position: relative;
    background-color: orange;
    color: black;
    border: solid 1px rgb(194, 100, 0);
    border-radius: 10px;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    height: 2rem;
    cursor: pointer;
    transition: 0.3s;
    padding: 1px 3px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px, rgba(0, 0, 0, 0.22) 0px 5px 2px;
    &:hover{
        transform: scale(0.93);
        color: white;
        background-color: black;
    }
}