body.active-modal {
  overflow-y: hidden;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 20px;
  padding: 15px 0;
}
.bold {
  font-weight: bold;
}

.defaultButton {
  width: 70px;
  height: 30px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: turquoise;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: 0.3s;
}

.addPushUps:hover {
  color: black;
}

.productImg {
  max-width: 88vw;
  max-height: 88vh;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;

}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background-color: #f1f1f1;
  padding: 3%;
  border-radius: 3px;
  // max-width: 600px;
  min-width: 300px;
  z-index: 4;
  max-height: 90vh;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}


.closeModal {
  position: absolute;
  right: 10px;
  background-color: #f1f1f1;
  border-radius: 30%;
  top: 10x;
  cursor: pointer;
  transition: .3s;
  &:hover{
    transform: scale(1.05);
  }
}


.imgContainer{
  max-width: 100%;
  max-height: 80%;
  min-width: 100%;
}

.defaultImage{
  max-width: 100%;
  max-height: 100%;

  &:hover{
      cursor: pointer;
  }

  @media (max-width:900px) {
      max-width: 85%;
  }
}
.selectorImage{
  display: block;
  border-radius: 15px;
  max-width: 100%;
  max-height: 100%;
}

.pageLayout{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.imgSelector{
  display: flex;
  gap: 15px;
  align-items: start;
  justify-content: center;
  max-height: 20%;
}

.selectionButton{
  max-height: 100%;
  padding: 3px;
  min-height: 10%;
  max-width: 90px;
  border-radius: 15px;
  &:hover{
    transform: scale(1.05);
    cursor: pointer;
    border: solid 0.1px black;
  }
}

.selectedSelectpr{
  border: solid 1px black;
}