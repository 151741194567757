.pageLayout{
    display: flex;
    max-width: 100%;
    flex-direction: column;
    padding-top: 50px;

}


.mainPage{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;
    overflow: hidden;
    @media (min-width:900px) {
        max-height: 90vh;

    }
    @media (max-width:900px) {
        flex-direction: column;
        align-items: center;
    }
}
.productImg{
    object-fit: contain;
    max-width: 60%;
    max-height: 70%;

    &:hover{
        cursor: pointer;
    }

    @media (max-width:900px) {
        max-width: 85%;
    }
}
.imgPreview{
    position: absolute;
    object-fit: contain;

    z-index: 999;
    top: 2.5%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 95%;
    max-height: 90vh;
    min-height: 70vh;
}

.infoBlock{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: end;
    width: 90%;
    padding: 10px 0;
}
.productName, .productSize, .productPrice{
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
    text-align: start;
    &.productSize{
        font-size: 1.5rem;
        color: rgb(62, 60, 60);
    }
    &.productName{
        font-size: 2.5rem;
    }
    &.productPrice{
        text-align: end;
        padding-top: 20px;
        font-size: 2rem;
        margin-right: 5px;
    }
}


.loader{
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    &::after {
        content: "";
        z-index: 999;
        position: absolute;
        width: 160px;
        height: 160px;
        border: 14px solid transparent;
        border-top-color: black;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  
    @keyframes button-loading-spinner {
      from {
        transform: rotate(0turn);
      }
    
      to {
        transform: rotate(1turn);
      }
    }

.addToCartButton{
    color: white;
    background-color: black;
    border-radius: 10px;
    height: 30px;
    width: 150px;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    gap: 10px;
    margin: 0 5px;
    justify-content: space-around;
    &:hover{
        background-color: white;
        color: black;
        transition: 0.2s;
        cursor: pointer;
        outline: solid 2px black;
    }
}
.description{
    max-height: 100%;
    text-align: justify;
    text-overflow: ellipsis;
}