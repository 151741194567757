.pageLayout{
    max-width: 100%;
    margin: 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    scroll-margin-top: 110px;
}

.card{
   width: 100%;
    gap: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 0 33%;
    position: relative;

}

// .visible{
//     transition: 1s;
//     background-color: red;
// }

.block{
  &:nth-child(5n+1){
    transition-delay: 150ms;
}
   
    &:nth-child(5n+2){
      transition-delay: 300ms;
  }
  &:nth-child(5n+3){
      transition-delay: 450ms;
  }
  &:nth-child(5n+4){
      transition-delay: 600ms;
  }
  &:nth-child(5n+5){
    transition-delay: 750ms;
}

@media (max-width:1355px) {
  &:nth-child(4n+1){
    transition-delay: 300ms;
}
  &:nth-child(4n+2){
    transition-delay: 450ms;
}
&:nth-child(4n+3){
    transition-delay: 600ms;
}
&:nth-child(4n+4){
  transition-delay: 750ms;
}
}

@media (max-width:1087px) {
  &:nth-child(3n+1){
    transition-delay: 450ms;
}
&:nth-child(3n+2){
    transition-delay: 600ms;
}
&:nth-child(3n+3){
  transition-delay: 750ms;
}
}

@media (max-width:818px) {
  &:nth-child(2n+1){
    transition-delay: 450ms;
}
&:nth-child(2n+2){
    transition-delay: 600ms;
}
}

@media (max-width:700px) {
  &:nth-child(2n+1){
    transition-delay: 0ms;
}
&:nth-child(2n+2){
    transition-delay: 0ms;
}}
}

.alfa{
    background-color: green;
    width: 59px;
    height: 50px;
}

.productsFilter{
    gap: 10px;
    display: flex;
    align-items: center;
    right: 0;
}

.selectBox{
    border-radius: 15px;
    height: 1.5rem;
    text-align: center;
    font-size: 1.1rem;
}

.label{
    text-align: center;
    width: 100%;
}

.loadMore{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadMoreButton{
    // height: 40px;
    border-radius: 20px;
    background-color: orange;
    display: flex;
    align-items: center;
    padding: 10px;
    font-weight: 500;
    &:hover{
        cursor: pointer;
        transform: scale(1.08);
    }
}

.loader{
    min-height: 200px;
    padding-top: 10px;
    align-items: center;
    @media (max-width:1100px) {
      min-height: 150px;
    }
      &::after {
        content: "";
        position: absolute;
        width: 160px;
        height: 160px;
        @media (max-width:1100px) {
          width: 100px;
          height: 100px;
        }
        left: 0;
        right: 0;
        margin: auto;
        border: 14px solid transparent;
        border-top-color: black;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
        pointer-events: none;
        cursor: not-allowed;
      }

    }
  
    @keyframes button-loading-spinner {
      from {
        transform: rotate(0turn);
      }
    
      to {
        transform: rotate(1turn);
      }
    }

    .hidden{
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100%);
      transition: all 1s;
  }
  .show{
      opacity: 1;
      filter: blur(0);
      transform: translateX(0);
  }

  #fullpage {
    display: none;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-color: black;
  }