.container{
    select{
        font-size: 1em;
      border-radius: 10px;
      padding: 10px ;
      width: 100%;
      font-size: 0.90em;
      letter-spacing: 0.02em;
        text-transform: uppercase;
    }
  
}
.dropdown{
    width: 100%;
    //   padding: 10px;
      font-size: 1em;
    position: relative;
    text-align: start;
    font-size: 0.90em;
    z-index: 1;
    // border-radius: 10px;
    // border: 2px solid black;
        text-transform: uppercase;
        transition: 0.5s;
        letter-spacing: 0.02em;
    &:hover{
        cursor: pointer;
    }
    input {
        width: 95%;
        padding: 10px;
        font-size: 1em;
        border-radius: 10px;

      }
}
// .countrySelected{
//     position: absolute;
//     color: black;
//     transform: translateX(0px) translateY(-17px);
//     font-size: 0.65em;
//     font-weight: 550;
//     padding: 0 10px;
//     background-color: white;
//     border-left: 1px solid black;
//     border-right: 10px solid black;
//     letter-spacing: 0.2em;
//     transition: 0.5s;
    
//   }

.option{
    margin: auto;
    width: 90%;
    padding: 2px 10px;
    font-weight: 550;
    font-size: 1.1em;
    //  border: 1px solid grey;

    background-color: white;
    &:hover{
        background-color: black;
        color: white;
        cursor: pointer;
    }
}
.dropdownMenu{
    display: flex;
    position: absolute;
    flex-direction: column;
    list-style-type: none;
    border: 1px solid grey;
    background-color: white;
    border-radius: 20px;
    margin: 5px 0;
    padding: 0;
    width: 100%;
    z-index: 3;
    max-height: 700%;
    overflow-y: scroll;
    left: 0px;
    right: 10%;

}
.dropdownLabel{
    position: absolute;
    left: 0px;
    top: 0;
    padding: 10px 10px;
    pointer-events: none;
    font-size: 0.90em;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 0.02em;
}
input:not(:placeholder-shown) ~ span,
      input:focus ~ span {
        color: black;
        transform: translateX(10px) translateY(-7px);
        font-size: 0.65em;
        font-weight: 550;
        padding: 0 10px;
        background-color: white;
        border-left: 1px solid black;
        border-right: 1px solid black;
        letter-spacing: 0.2em;
      }