.loaderWrapper, .loadedWrapperr{
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    gap: 10px;
    font-weight: bold;
    font-size: 40px;
    flex-direction: row;
    background-color: white;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

#loadedWrapper{
  animation: open 2s ;
}

.loaderWrapper{
  gap: 30px;

  @media (max-width:500px) {
    flex-direction: column;
    gap: 0;
  }
}

.container{
  height: 80px;
  width: 45%;
}

.loaderR{
  animation: rotation 1.5s ease-out infinite;
  position: absolute;
  left: 4;
}

.loaderS{
  animation: rotationBack 2.5s infinite;
   position: absolute;
   left: 4;

}

#hidden{
  display: none
}

.container{
  width: 20px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.loadedContainer{
  width: 140px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: growS 2s;
  overflow: hidden;
  &#rapolas{width: 130px;
    animation: grow 2s;
}
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(-720deg);
  }
} 

@keyframes moveToLeft {
  0% {
      transform: translateX(0);
  }
  100%{
      transform: translateX(-5px);
  }
}

  @keyframes grow {
    0% { width: 20px;}
    100% {width: 130px;}
  }

  @keyframes growS {
    0% { width: 20px;}
    100% {width: 140px;}
  }

  @keyframes open {
    0%{transform: translateY(0)}
    100%{transform: translateY(-200vh)}
  }