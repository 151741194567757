
.customerForm{
    width: 50%;
    border: 1px solid black;
    background-color: white;
    border-radius: 30px;
    @media (max-width:1100px) {
      width: 100%;
    }

}

.twoInLine{
  width: 100% ;
    display: flex;
    gap: 25px;
}
.form{
    padding: 0 0 5% 0;
    margin: 0 7%;
    width: 86%;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.paymentOptions{
    width: 40%;
}

.inputBox {
    position: relative;
    width: 100%;

    input {
      width: 95%;
      padding: 10px;
      font-size: 1em;
      border-radius: 10px;

    }
    span {
        position: absolute;
        left: 0px;
        top: 0;
        padding: 10px 0px 10px 10px; 
        pointer-events: none;
        font-size: 0.9em;
        text-transform: uppercase;
        transition: 0.5s;
    
      
        letter-spacing: 0.02em;

      }
      input:not(:placeholder-shown) ~ span,
      input:focus ~ span {
        color: white;
        transform: translateX(10px) translateY(-7px);
        font-size: 0.65em;
        font-weight: 550;
        padding: 0 10px;
        background-color: black;
        border-left: 1px solid white;
        border-right: 1px solid white;
        letter-spacing: 0.2em;
      }
      &:nth-child(2) input:focus ~ span,
      &:nth-child(2) input:not(:placeholder-shown) ~ span {
        background-color: white;
        color: black;
        border-left: 1px solid black;
        border-right: 1px solid black;
      }
  }

  .countrySelect{
    width: 100%;
  }

  .invalidInput{
    outline: solid 2px red;
  }
  .disabled{
    background-color: dimgrey;
    color: linen;
    opacity: .7;
  }