.App {
  text-align: center;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;
  font-family: Montserrat Bold, Fallback, sans-serif;
  .background{
    width: 100%;
    height: 100%;
    z-index: -99;
    position: absolute;
    opacity: 0.5;
    left: 0;
  }
}
a{
  color: rgb(105, 154, 182)
}

.backgroundTest{
  background-color: rgb(71, 38, 38);
  width: 100%;
  height: 100%;
  z-index: -99;
    position: absolute;
}

.loader{
  z-index: 999;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  scroll-behavior: none;
}

.blank {
  height: 500px;
}

.container{
  margin: 100px auto;
  max-width: 2200px;
}

.pageLayout {
  margin: 30px 15%;
  max-width: 2200px;
  
  @media (max-width:1600px) {
    margin: 0 8%;
  }
  @media (max-width:700px) {
    margin: 0 5%;
  }
}

.aactive{
  background-color: red;
}

h2{
  font-size: 1.88rem;
  font-weight: 600;
  margin: 25px 0 ;
}

